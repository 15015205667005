import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import BlogRollPost from './BlogRollPost'

export class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className="p-6 md:p-4 md:pt-8">
        <ul>
          {posts &&
            posts.map(({ node: post }) => {
              return (
                post.frontmatter.publish && (
                  <li key={post.id}>
                    <BlogRollPost
                      title={post.frontmatter.title}
                      slug={post.fields.slug}
                      date={post.frontmatter.date}
                      readingTime={post.fields.readingTime}
                      featuredpost={post.frontmatter.featuredpost}
                      featuredimage={post.frontmatter.featuredimage}
                      excerpt={post.excerpt}
                      className="mb-5 md:mb-8"
                    />
                  </li>
                )
              )
            })}
        </ul>
      </section>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 10000 # Amount of blog posts previews to display
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
                readingTime {
                  text
                }
              }
              frontmatter {
                title
                publish
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 352, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
