import React from 'react'
import PropTypes from 'prop-types'
import Link from './CustomLink'
import PostInfo from './PostInfo'
import Excerpt from './Excerpt'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const BlogRollPost = ({
  title,
  slug,
  date,
  readingTime,
  featuredpost,
  featuredimage,
  excerpt,
  className,
}) => {
  return (
    <article className={`pt-4 ${className} ${featuredpost ? 'is-featured' : ''}`}>
      <header>
        <Link className="text-3xl font-medium leading-tight" to={slug}>
          {title}
        </Link>
        <PostInfo date={date} readingTime={readingTime} className="mt-2" />
        {/* image */}
        {featuredimage ? (
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredimage,
              alt: `Image thumbnail for post ${title}`,
            }}
            className="mt-3 md:mt-6"
          />
        ) : null}
      </header>
      <div className="mt-3 md:mt-6">
        <Excerpt content={excerpt} />
        <div className="text-left mt-3">
          <Link to={slug}>Read More →</Link>
        </div>
      </div>
    </article>
  )
}

BlogRollPost.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  readingTime: PropTypes.object.isRequired,
  featuredpost: PropTypes.bool,
  featuredimage: PropTypes.object,
  excerpt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default BlogRollPost
