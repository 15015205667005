import React from 'react'
import PropTypes from 'prop-types'

const excerpt = ({ content, className }) => <p className={className}>{content}</p>

excerpt.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default excerpt
